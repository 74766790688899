<template>
	<div class="content-wrap">
		<!--项目信息报表-->
		<Spin fix v-if="loading"></Spin>
		<Spin fix v-if="searchLoading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" :isShowReset="false">
			<CtmsSearchBoxItem :span="1" label="项目类型">
				<Select
					v-model="searchParams.projectTypeId"
					:transfer="true"
					filterable
					placeholder="请选择项目类型"
				>
					<Option
						:value="item.id"
						v-for="item in projectTypeList"
						:key="item.id"
						>{{ item.typeName }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="项目阶段">
				<Select
					v-model="searchParams.projectStep"
					clearable
					:transfer="true"
					placeholder="请选择项目阶段"
				>
					<Option
						:value="item.obj.nickname"
						v-for="item in projectStep"
						:key="item.obj.nickname"
						>{{ item.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<template v-if="env === 'shetyy'">
				<CtmsSearchBoxItem :span="1" label="项目申请人">
					<Input
						placeholder="请输入项目申请人"
						v-model="searchParams.trialProjectLeader"
					/>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="科室">
					<Select
						v-model="searchParams.trialSiteDepartment"
						:transfer="true"
						filterable
						clearable
						placeholder="请选择科室"
					>
						<Option :value="item.name" v-for="item in departList" :key="item.id"
							>{{ item.name }}
						</Option>
					</Select>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="项目类别">
					<Select
						v-model="searchParams.trialPartnerType"
						clearable
						:transfer="true"
						placeholder="请选择项目类别"
					>
						<Option
							:value="item.label"
							v-for="item in trialPartnerTypeList"
							:key="item.value"
							>{{ item.label }}
						</Option>
					</Select>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="中心类型">
					<Select
						v-model="searchParams.siteType"
						clearable
						:transfer="true"
						placeholder="请选择中心类型"
					>
						<Option
							:value="item.label"
							v-for="item in siteTypeList"
							:key="item.value"
							>{{ item.label }}
						</Option>
					</Select>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="项目开始时间">
					<DatePicker
						type="daterange"
						placeholder="请选择项目开始时间"
						:transfer="true"
						style="width: 100%"
						format="yyyy-MM-dd"
						:value="startTimes"
						@on-change="v => (startTimes = v)"
					></DatePicker>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="项目结束时间">
					<DatePicker
						type="daterange"
						placeholder="请选择项目结束时间"
						:transfer="true"
						style="width: 100%"
						format="yyyy-MM-dd"
						:value="endTimes"
						@on-change="v => (endTimes = v)"
					></DatePicker>
				</CtmsSearchBoxItem>
			</template>
			<template v-if="env === 'xinhua'">
				<CtmsSearchBoxItem :span="1" label="研究发起方">
					<Select
						v-model="searchParams.publicInitiator"
						clearable
						:transfer="true"
						placeholder="请选择研究发起方"
					>
						<Option
							:value="item.label"
							v-for="item in trialInitiateList"
							:key="item.value"
							>{{ item.label }}
						</Option>
					</Select>
				</CtmsSearchBoxItem>
			</template>
			<CtmsSearchBoxItem :span="1" label="创建日期">
				<DatePicker
					type="daterange"
					placeholder="请选择创建日期"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportData">导出项目信息报表</Button>
			<Button @click="exportAllData">导出全部项目信息报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="getTableCols"
			:data="data"
			:page="page"
			ref="table"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/report/report"
import projectApi from "@/api/project/project"
import publicApi from "@/api/public"
import fundsApi from "@/api/project/funds"
import { mapState } from "vuex"

const { apiGetProjectInfo, apiGetConDetail } = api
const { apiGetProjTypeList } = projectApi
const { publicGetDictionaryList } = publicApi
const { apiGetDeparts } = fundsApi

export default {
	name: "ProjectInfo",
	data() {
		return {
			headers: [],
			columns: [
				{
					title: "项目类型",
					key: "project_config_name",
					minWidth: 120
				},
				{
					title: "项目阶段",
					key: "project_step",
					minWidth: 120
				}
			],
			data: [],
			loading: false,
			searchLoading: false,
			testTypeList: [],
			trialTypeList: [],
			searchParams: {
				projectTypeId: "",
				projectStep: "",
				trialProjectLeader: "",
				trialSiteDepartment: "",
				trialPartnerType: "",
				siteType: "",
				publicInitiator: ""
			},
			oldSearchParams: {},
			projectTypeList: [],
			projectStep: [],
			trialPartnerTypeList: [],
			siteTypeList: [],
			departList: [],
			times: [],
			startTimes: [],
			endTimes: [],
			trialInitiateList: [],
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 135
		}),
		...mapState("user", ["processList"]),
		// 是否多中心
		siteCatgList() {
			return this.$store.state.enumerate.SITE_CATEGORY
		},
		// 是否组长单位
		isLeaderList() {
			return this.$store.state.enumerate.LEADER_STYLE
		},
		getTableCols() {
			return this.headers.map(item => Object.assign({ minWidth: 150 }, item))
		},
		env() {
			return this.$store.state.environment
		},
		orgId() {
			return this.$store.state.user.info.orgId
		}
	},
	mounted() {
		this.getPublicData()
		if (this.env === "shetyy") {
			this.getDepartList()
		}
		this.getProjectStepInfo()
		this.getProjectTypeList()
	},
	methods: {
		async getPublicData() {
			this.searchLoading = true
			// 试验产品类别
			const res = await publicGetDictionaryList()
			if (res) {
				this.testTypeList = res.data.find(x => x.id === "10").leaf
				// 试验类型
				this.trialTypeList = res.data.find(x => x.id === "13").leaf
			}

			// 根据别名获取表单组件信息详情
			const ret = await apiGetConDetail({
				alias: "public_Initiator" // 研究发起方
			})
			if (ret) {
				this.trialInitiateList = ret || []
			}

			if (this.env === "shetyy") {
				const res2 = await apiGetConDetail({
					alias: "trial_partner_type" // 项目类别
				})
				if (res2) {
					this.trialPartnerTypeList = res2 || []
				}
				const res3 = await apiGetConDetail({
					alias: "site_type" // 中心类型
				})
				if (res3) {
					this.siteTypeList = res3 || []
				}
			}
			this.searchLoading = false
		},
		// 科室
		getDepartList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data
				}
				this.loading = false
			})
		},
		// 项目类型
		getProjectTypeList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProjTypeList()
				if (res) {
					this.projectTypeList = res.data
					this.searchParams.projectTypeId = this.projectTypeList.length
						? this.projectTypeList[0].id
						: ""
					this.oldSearchParams.projectTypeId = this.searchParams.projectTypeId
					this.initData()
				} else {
					this.loading = false
				}
			})
		},
		// 项目阶段
		getProjectStepInfo() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList({ type: 25, isDel: 0 })
				if (res) {
					const arr = res.data && res.data.length ? res.data[0].leaf : []
					const info = arr.map(item => ({
						...item,
						obj: {
							...item.obj,
							nickname: Number(item.obj.nickname)
						}
					}))
					this.projectStep = info
				}
				this.loading = false
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const { projectStep, projectTypeId, publicInitiator } =
					this.oldSearchParams
				const step = projectStep || projectStep === 0 ? projectStep : ""
				let params = {
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					projectTypeId: projectTypeId || "",
					projectStep: step,
					publicInitiator: publicInitiator || "",
					createTimeStart: this.times ? this.times[0] : "",
					createTimeEnd: this.times ? this.times[1] : ""
				}
				if (this.env === "shetyy") {
					const {
						trialProjectLeader,
						trialSiteDepartment,
						trialPartnerType,
						siteType
					} = this.oldSearchParams
					params = {
						...params,
						trialProjectLeader: trialProjectLeader || "",
						trialSiteDepartment: trialSiteDepartment || "",
						trialPartnerType: trialPartnerType || "",
						siteType: siteType || "",
						projectStartTimeFrom: this.startTimes ? this.startTimes[0] : "",
						projectStartTimeTo: this.startTimes ? this.startTimes[1] : "",
						projectEndTimeFrom: this.endTimes ? this.endTimes[0] : "",
						projectEndTimeTo: this.endTimes ? this.endTimes[1] : ""
					}
				}
				const res = await apiGetProjectInfo({
					...params
				})
				if (res && res.list) {
					this.headers = [...this.columns]
					const obj = res.list[0]
					for (const key in obj) {
						if (key !== "project_config_name" && key !== "project_step") {
							this.headers.push({
								title: obj[key],
								key
							})
						}
					}
					this.data = res.list.slice(1)
					this.page.total = res.total
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				projectTypeId: "",
				projectStep: "",
				trialProjectLeader: "",
				trialSiteDepartment: "",
				trialPartnerType: "",
				siteType: "",
				publicInitiator: ""
			}
			this.times = []
			this.startTimes = []
			this.endTimes = []
			this.searchHandle()
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 导出项目信息
		exportData() {
			let url = `${this.$baseUrl}/report/export-project-info?token=${this.$store.state.user.token}`
			if (this.env === "shetyy") {
				const {
					projectTypeId,
					trialProjectLeader,
					projectStep,
					trialSiteDepartment,
					trialPartnerType,
					siteType
				} = this.oldSearchParams
				const step = projectStep || projectStep === 0 ? projectStep : ""
				const sTimeS =
					this.startTimes.length && this.startTimes[0] ? this.startTimes[0] : ""
				const sTimeE =
					this.startTimes.length && this.startTimes[1] ? this.startTimes[1] : ""
				const eTimeS =
					this.endTimes.length && this.endTimes[0] ? this.endTimes[0] : ""
				const eTimeE =
					this.endTimes.length && this.endTimes[1] ? this.endTimes[1] : ""
				const sTime = this.times.length && this.times[0] ? this.times[0] : ""
				const eTime = this.times.length && this.times[1] ? this.times[1] : ""
				url += `&projectTypeId=${projectTypeId || ""}&trialProjectLeader=${trialProjectLeader || ""}&projectStep=${step}&trialSiteDepartment=${trialSiteDepartment || ""}&trialPartnerType=${trialPartnerType || ""}&siteType=${siteType || ""}&projectStartTimeFrom=${sTimeS}&projectStartTimeTo=${sTimeE}&projectEndTimeFrom=${eTimeS}&projectEndTimeTo=${eTimeE}&createTimeStart=${sTime}&createTimeEnd=${eTime}`
			} else {
				const { projectTypeId, projectStep, publicInitiator } =
					this.searchParams
				const sTime = this.times.length && this.times[0] ? this.times[0] : ""
				const eTime = this.times.length && this.times[1] ? this.times[1] : ""
				url += `&projectTypeId=${projectTypeId || ""}&projectStep=${projectStep || ""}&createTimeStart=${sTime}&createTimeEnd=${eTime}`
				if (this.env === "xinhua") {
					url += `&publicInitiator=${publicInitiator || ""}`
				}
			}
			window.open(url)
		},
		// 导出全部项目信息
		exportAllData() {
			window.open(
				`${this.$baseUrl}/report/export-project-info-all?token=${this.$store.state.user.token}`
			)
		}
	}
}
</script>
